export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Admin',
        route: 'admin',
        icon: 'UserIcon',
      },
      {
        title: 'Customer',
        route: 'customer',
        icon: 'UserIcon',
      },
      {
        title: 'Provider',
        route: 'provider',
        icon: 'UserIcon',
      },
      {
        title: 'Orders',
        route: 'orders',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
